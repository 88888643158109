import { Container, Snackbar, Slide, useMediaQuery, useTheme, AppBar, Toolbar, IconButton, Icon, Typography, Avatar, CircularProgress, ClickAwayListener, Collapse, Popper, Tooltip, withStyles, Grow, TextField, MenuItem, MenuList, InputAdornment, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Button, makeStyles, CssBaseline, Paper, Dialog, DialogTitle, DialogContent, Grid } from '@material-ui/core'
import clsx from 'clsx'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { user_api, hp } from 'services'
import logo from '../logo.svg'
import context from 'services/context'

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const SlideTransition = (props) => {
    return <Slide {...props} direction="up" />
}

const _Container = ((props) => {
    const history = useHistory()
    const classes = useStyles();
    const { children, id, className, ...other } = props
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(localStorage.getItem('selectedIndex') ? parseFloat(localStorage.getItem('selectedIndex')) : 0)
    const [openProfile, setOpenProfile] = useState(null)
    const anchorRef = useRef(null)
    const [name, setName] = useState(localStorage.getItem('name') || "Admin")
    const [loginAt, setLoginAt] = useState(localStorage.getItem('login_at'))
    const { snack } = useContext(context)

    const handleDrawerOpen = () => {
        // localStorage.setItem('sidebar_open', true)
        setOpen(true)
    }
    const handleDrawerClose = () => {
        localStorage.setItem('sidebar_open', false)
        setOpen(false)
    }

    const onChangeRoute = (route, index) => {
        if (index == 99) {
            setOpenProfile(null)
        }
        setOpen(false)
        history.push(route)
        setSelectedIndex(index)
        localStorage.setItem('selectedIndex', index)
    }

    const onOpenProfile = (event) => {
        setOpenProfile(event.currentTarget)
    }
    const onCloseProfile = () => {
        setOpenProfile(null)
    }

    const onLogout = async () => {
        let c = window.confirm("Please confirm to logout")
        if (c) {
            await user_api.post(`logout`)
            hp.user_logout()
            history.push('/')
        }
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed">
                <Container maxWidth="xl" disableGutters>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setOpen(true)}
                            edge="start"
                        >
                            <Icon>menu</Icon>
                        </IconButton>
                        <Typography variant="h1" noWrap>EMS</Typography>
                        <div style={{ marginLeft: 'auto' }}>
                            <IconButton ref={anchorRef} aria-controls={Boolean(openProfile) ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={onOpenProfile}>
                                <Avatar alt={name} src={`https://ui-avatars.com/api/?name=${name}`} />
                            </IconButton>
                            <Popper open={Boolean(openProfile)} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 100, marginTop: 2 }}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={onCloseProfile}>
                                                <MenuList autoFocusItem={Boolean(openProfile)} id="menu-list-grow">
                                                    <MenuItem><Icon className="mr10">emoji_people</Icon> Hello {name}</MenuItem>
                                                    <MenuItem onClick={() => onChangeRoute('/profile', 99)}><Icon className="mr10">person</Icon> Profile</MenuItem>
                                                    <MenuItem onClick={onLogout}><Icon className="mr10">exit_to_app</Icon> Log out</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer open={open} onClose={() => setOpen(false)} >
                <List className="drawer-list">
                    <ListItem button onClick={() => onChangeRoute('/dashboard', 0)} selected={selectedIndex === 0} className="menu-active">
                        <ListItemIcon><Icon>dashboard</Icon></ListItemIcon>
                        <ListItemText>Dashboard</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => onChangeRoute('/report', 8)} selected={selectedIndex === 8} className="menu-active">
                        <ListItemIcon><Icon>bar_chart</Icon></ListItemIcon>
                        <ListItemText>Reports</ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => onChangeRoute('/wallet-type', 1)} selected={selectedIndex === 1} className="menu-active">
                        <ListItemIcon><Icon>account_balance_wallet</Icon></ListItemIcon>
                        <ListItemText>Wallet Types</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => onChangeRoute('/wallet', 2)} selected={selectedIndex === 2} className="menu-active">
                        <ListItemIcon><Icon>account_balance</Icon></ListItemIcon>
                        <ListItemText>Wallets</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => onChangeRoute('/category', 3)} selected={selectedIndex === 3} className="menu-active">
                        <ListItemIcon><Icon>category</Icon></ListItemIcon>
                        <ListItemText>Categories</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => onChangeRoute('/transaction', 4)} selected={selectedIndex === 4} className="menu-active">
                        <ListItemIcon><Icon>import_export</Icon></ListItemIcon>
                        <ListItemText>Transactions</ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => onChangeRoute('/income', 5)} selected={selectedIndex === 5} className="menu-active">
                        <ListItemIcon><Icon>local_atm</Icon></ListItemIcon>
                        <ListItemText>Incomes</ListItemText>
                    </ListItem>
                    <ListItem button onClick={() => onChangeRoute('/expense', 6)} selected={selectedIndex === 6} className="menu-active">
                        <ListItemIcon><Icon>local_atm</Icon></ListItemIcon>
                        <ListItemText>Expenses</ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => onChangeRoute('/note', 7)} selected={selectedIndex === 7} className="menu-active">
                        <ListItemIcon><Icon>notes</Icon></ListItemIcon>
                        <ListItemText>Notes</ListItemText>
                    </ListItem>
                </List>
            </Drawer>

            <Container id={id} maxWidth="xl" className={`container ${className}`}>
                <div className={classes.toolbar} />
                {React.cloneElement(props.children)}
                <Snackbar
                    key={snack ? snack.key : undefined}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={snack ? snack.open : false}
                    autoHideDuration={10000}
                    TransitionComponent={SlideTransition}
                    onClose={snack ? snack.onClose : undefined} >
                    <Alert onClose={snack && snack.onClose} severity={snack && snack.type}>
                        {snack && snack.message}
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    )
})

export default _Container