import React, { useEffect, useState } from 'react'
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, makeStyles, Container, Icon, CircularProgress, Collapse } from '@material-ui/core'
import { hp, user_api } from 'services'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">Expense Management System</Link>
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Page() {
    const history = useHistory()
    const classes = useStyles()
    const [errorMessage, setErrorMessage] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('user_token')) {
            onStart()
        }
    }, [])

    const onStart = async () => {
        const res = await user_api.get(`profile`)
        const { data } = res
        if (data.error) {
            hp.user_logout()
            return
        }
        localStorage.setItem('selectedIndex', 0)
        history.push('/dashboard')
    }

    const onLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        const res = await user_api.post(`login`, { email, password })
        const { data } = res
        setLoading(false)
        if (data.error) {
            setOpen(true)
            setErrorMessage(data.message)
            return
        }
        else {
            const loginData = data?.data
            localStorage.setItem('user_token', loginData?.token)
            localStorage.setItem('name', loginData?.name)
            // localStorage.setItem('login_at', loginData?.login_at)
            localStorage.setItem('selectedIndex', 0)
            history.push('/dashboard')
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <Icon>lock_outlined</Icon>
                </Avatar>
                <Typography component="h1" variant="h5">Sign in</Typography>
                <form className={classes.form} onSubmit={onLogin}>
                    <TextField variant="outlined" margin="normal" required fullWidth id="email"
                        label="Email Address" name="email" autoComplete="email" autoFocus
                        value={email} onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField variant="outlined" margin="normal" required fullWidth name="password"
                        label="Password" type="password" id="password" autoComplete="current-password"
                        value={password} onChange={(e) => setPassword(e.target.value)}
                    />
                    {open &&
                        <Grid container>
                            <Grid item xs>
                                <Collapse in={open}>
                                    <Alert variant="outlined" severity="error" onClose={() => setOpen(false)}>{errorMessage}</Alert>
                                </Collapse>
                            </Grid>
                        </Grid>
                    }
                    {loading ?
                        <Button fullWidth variant="contained" color="primary" className={classes.submit}><CircularProgress color="inherit" size={25} /></Button>
                        :
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Sign In</Button>
                    }
                    {/* <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="#" variant="body2">Forgot password?</Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}