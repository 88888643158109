const hp = {
  admin_logout: () => {
    localStorage.removeItem('admin_token')
    localStorage.removeItem('name')
    window.location.href = "/"
  },
  user_logout: () => {
    localStorage.removeItem('user_token')
    localStorage.removeItem('user_name')
    window.location.href = "/"
  },
  format_money: (money, presice = false) => {
    if (money == null || money == '') {
      return 0
    }
    money = parseInt(money)
    money = (money).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    if (presice) {
      return money
    } else {
      return money.substring(0, money.length - 3)
    }
  },
  FormatMoney: (money) => {
    return new Intl.NumberFormat('MMK', { maximumSignificantDigits: 10 }).format(money);
  },
  d: (date) => {
    return date.replace(/-/g, "/");
  }
}

export default hp