import React, { useState, useRef, useContext } from 'react'
import MaterialTable, { MTableToolbar, MTableAction } from 'material-table'
import { user_api, context } from 'services'
import { Typography, makeStyles, Icon, IconButton } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    tbTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));

const Page = () => {
    const tableRef = useRef()
    const { setSnack } = useContext(context)
    const classes = useStyles()

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            <MaterialTable tableRef={tableRef}
                // title={
                //     <Typography className={classes.tbTitle}>Wallet Types</Typography>
                // }
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h5" className={`${classes.tbTitle}`}>Wallet Types</Typography>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                columns={[
                    { title: 'Name', field: 'name' }
                ]}
                data={query =>
                    new Promise(async (resolve) => {
                        let filters = []
                        if (query.filters.length > 0) {
                            query.filters.map(data => {
                                let column = {}
                                let value = []
                                column.field = data.column.field
                                value = data.value
                                filters.push({ 'column': column, 'value': value })
                            })
                            filters = JSON.stringify(filters)
                        }
                        const result = await user_api.get(`wallet-type?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}&filters=${filters}`)
                        const { data } = result
                        resolve({
                            data: data.data,
                            page: query.page,
                            totalCount: data.meta.total,
                        })
                    })
                }
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    sorting: false,
                    search: true,
                    filtering: true,
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: { width: 190 }
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise(async (resolve, reject) => {
                            const res = await user_api.post('wallet-type', newData)
                            let { data } = res
                            if (data.error) {
                                showSnack(data.message, 'error')
                                reject()
                            } else {
                                showSnack("Successfully created.", 'info')
                            }
                            resolve()
                        }),
                    onRowUpdate: newData =>
                        new Promise(async (resolve, reject) => {
                            const res = await user_api.put(`wallet-type/${newData.id}`, newData)
                            let { data } = res
                            if (data.error) {
                                showSnack(data.message, 'error')
                                reject()
                            } else {
                                showSnack("Successfully updated.", 'info')
                            }
                            resolve()
                        }),
                    // onRowDelete: oldData =>
                    //     new Promise(async (resolve) => {
                    //         await user_api.delete(`wallet-type/${oldData.id}`)
                    //         resolve()
                    //     })
                }}
            />
        </div>
    )
}
export default Page