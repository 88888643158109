import axios from 'axios';
import { hp } from 'services';
import constant from './config'

const instance = axios.create();

instance.interceptors.request.use(async (config,) => {
  config.baseURL = `${constant.api_url}/api/admin`
  config.headers.authorization = 'Bearer ' + localStorage.getItem('admin_token')

  return config

}, (error) => {
  return Promise.reject(error);
})

instance.interceptors.response.use(async (response) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log("response", response)
  }

  if (!response.data) {
    return Promise.reject(response);
  }

  if (response.data.error) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('having error ', response.data)
    }
  }

  return response

}, async (error) => {
  const { response } = error
  if (process.env.NODE_ENV !== 'production') {
    console.log("error ", response)
  }
  if (response && response.status == 401) {
    hp.admin_logout();
    return Promise.reject(response)
  } else {
  }
  return Promise.reject(error);
})

export default instance
