import React, { useState, useRef, useEffect, useContext } from 'react'
import { CustomDialogTitle } from 'components'
import MaterialTable, { MTableToolbar } from 'material-table'
import { user_api, context } from 'services'
import { Grid, Typography, Paper, Popover, Button, Dialog, DialogContent, DialogActions, Icon, makeStyles, TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, addDays, subDays, startOfMonth } from 'date-fns'
import { DateRangePicker, DateRange } from 'react-date-range'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Fade } from 'react-reveal'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles((theme) => ({
    tbTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Page = () => {
    const tableRef = useRef()
    const { setSnack } = useContext(context)
    const [lookupWallet, setLookupWallet] = useState(null)
    const [wallets, setWallets] = useState(null)
    const [lookupCategory, setLookupCategory] = useState(null)
    const [categories, setCategories] = useState(null)
    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [refund, setRefund] = useState({ id: null, refund_amount: '', refund_wallet_id: null, refund_note: '', refund_at: '' })
    const [loading, setLoading] = useState(false)
    const [expense, setExpense] = useState({ expense_at: null, category_id: null, wallet_id: null, amount: '', description: '' })
    const [category, setCategory] = useState(null)
    const [wallet, setWallet] = useState(null)
    const [range, setRange] = useState([{ startDate: startOfMonth(new Date()), endDate: new Date(), key: 'selection' }])
    const [anchorEl, setAnchorEl] = useState(null)
    const [filterLoading, setFilterLoading] = useState(false)
    let use_query = useQuery();
    const [rowPerPage, setRowPerPage] = useState(use_query.get("row_per_page"))
    const [page, setPage] = useState(use_query.get("page"))
    const [search_text, setSearchText] = useState(use_query.get("search"))
    const [expenseDate, setExpenseDate] = useState(use_query.get("expense_date"))
    const classes = useStyles()

    const showRange = Boolean(anchorEl)

    useEffect(() => {
        onStart()
    }, [])
    const onStart = async () => {
        const res = await user_api.get(`wallet?limit=100`)
        const resp = await user_api.get(`category?limit=100&type=expense`)
        const { data } = res.data
        const catData = resp.data
        if (data.error) {
            showSnack(data.message)
            return
        } else {
            setWallets(data)
            setCategories(catData)
            let _data = {}
            for (var i = 0; i < data.length; i++) {
                _data[data[i].id] = data[i].name
            }
            setLookupWallet(_data)
            let _cat_data = {}
            for (var i = 0; i < catData.length; i++) {
                _cat_data[catData[i].id] = catData[i].name
            }
            setLookupCategory(_cat_data)
        }
    }

    const openRefund = (rowData) => {
        let _refund = { ...refund }
        _refund.id = rowData?.id
        _refund.refund_amount = rowData?.refund_amount == '0.00' ? '' : rowData?.refund_amount
        _refund.refund_at = rowData?.refund_at
        _refund.refund_note = rowData?.refund_note
        wallets.forEach(element => {
            if (element.id == rowData?.refund_wallet_id) {
                setWallet(element)
                return
            }
        });
        setRefund(_refund)
        setOpen(true)
    }

    const openEdit = (rowData) => {
        let _expense = { ...expense }
        _expense.id = rowData?.id
        _expense.amount = rowData?.amount
        _expense.expense_at = rowData?.expense_at
        _expense.description = rowData?.description
        categories.forEach(element => {
            if (element.id == rowData?.category_id) {
                setCategory(element)
                return
            }
        });
        wallets.forEach(element => {
            if (element.id == rowData?.wallet_id) {
                setWallet(element)
                return
            }
        });
        setExpense(_expense)
        setOpenAdd(true)
    }

    const onAddOrUpdate = async (e) => {
        e.preventDefault()
        setLoading(true)
        let res
        if (expense?.id) {
            res = await user_api.put(`expense/${expense.id}`, { ...expense, category_id: category?.id, wallet_id: wallet?.id })
        } else {
            res = await user_api.post(`expense`, { ...expense, category_id: category?.id, wallet_id: wallet?.id })
        }
        let { data } = res
        setLoading(false)
        if (data.error) {
            showSnack(data.message, 'error')
            return
        } else {
            if (expense?.id)
                showSnack("Successfully updated.", 'info')
            else
                showSnack("Successfully created.", 'info')
        }
        setOpenAdd(false)
        setCategory(null)
        setWallet(null)
        setExpense({ expense_at: null, category_id: null, wallet_id: null, amount: '', description: '' })
        tableRef.current && tableRef.current.onQueryChange()
    }

    const onRefund = async (e) => {
        e.preventDefault()
        // if (refund?.refund_wallet_id == null) {
        //     showSnack("Please choose refund to wallet", 'error')
        //     return
        // }
        setLoading(true)
        const res = await user_api.post(`expense/${refund?.id}/refund`, { ...refund, refund_wallet_id: wallet?.id })
        const { data } = res
        setLoading(false)
        setOpen(false)
        if (data.error) {
            showSnack(data.message, 'error')
            return
        }
        setWallet(null)
        tableRef.current && tableRef.current.onQueryChange()
    }

    const onClose = () => {
        setOpen(false)
        setOpenAdd(false)
        setCategory(null)
        setWallet(null)
        setExpense({ expense_at: null, category_id: null, wallet_id: null, amount: '', description: '' })
        setRefund({ id: null, refund_amount: '', refund_wallet_id: null, refund_note: '', refund_at: '' })
    }

    const onFilter = async () => {
        setFilterLoading(true)
        // let start_date = format(range[0].startDate, 'yyyy-MM-dd')
        // let end_date = format(range[0].endDate, 'yyyy-MM-dd')
        tableRef.current && tableRef.current.onQueryChange()
        setFilterLoading(false)
        // tableRef.current && tableRef.current.onFilterChange(0, [start_date, end_date])
    }

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            <Fade up cascade opposite>
                <Paper className="p20 mb10">
                    <Grid container id="filter" justify="center" alignItems="center">
                        <Grid item xs={9} sm={5} lg={3} xl={2}>
                            <Typography>Please select a date range</Typography>
                            <Button variant="outlined" color="primary" onClick={(e) => { setAnchorEl(e.currentTarget) }} >
                                {format(range[0].startDate, 'dd-MM-yyyy')} &nbsp;&nbsp; to &nbsp;&nbsp; {format(range[0].endDate, 'dd-MM-yyyy')}
                            </Button>
                            <Popover
                                open={showRange}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}>
                                <DateRangePicker
                                    onChange={item => setRange([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={range}
                                    direction="horizontal"
                                    maxDate={new Date()}
                                />
                            </Popover>
                        </Grid>
                        <Grid item xs={3} sm={2} className="mt20">
                            {filterLoading ?
                                <Button variant="contained" color="primary" fullWidth><CircularProgress size={25} color="inherit" /></Button>
                                :
                                <Button variant="contained" color="primary" fullWidth onClick={onFilter}>Filter</Button>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
            <MaterialTable tableRef={tableRef}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h5" className={`${classes.tbTitle}`}>Expenses</Typography>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                columns={[
                    {
                        title: 'Expense at', field: 'expense_at', type: 'date',
                        filterComponent: (props) =>
                            <Typography></Typography>
                    },
                    {
                        title: 'Amount', field: 'amount', type: 'currency',
                        currencySetting: { currencyCode: 'MMK', minimumFractionDigits: 0 }
                    },
                    { title: 'Category', field: 'category_id', lookup: lookupCategory },
                    { title: 'Wallet', field: 'wallet_id', lookup: lookupWallet },
                    { title: 'Description', field: 'description' },
                    {
                        title: 'Refund Amount', field: 'refund_amount', type: 'currency', editable: false,
                        currencySetting: { currencyCode: 'MMK', minimumFractionDigits: 0 }
                    },
                    // { title: 'Refund to', field: 'refund_wallet_id', lookup: lookupWallet, editable: false },
                    // { title: 'Refund at', field: 'refund_at', type: 'date', editable: false },
                    // { title: 'Refund Note', field: 'refund_note', editable: false },
                ]}
                data={query =>
                    new Promise(async (resolve) => {
                        let filters = []
                        // let createdFilter = []
                        // let start_date = format(range[0].startDate, 'dd-MM-yyyy')
                        // let end_date = format(range[0].endDate, 'dd-MM-yyyy')
                        // // setRange([{ startDate: range[0].startDate, endDate: range[0].endDate, key: 'selection' }])
                        // let column = {}
                        // column.field = 'expense_at'
                        // createdFilter.push({ 'column': column, 'value': [start_date, end_date] })
                        // query.filters = createdFilter

                        // query.page = page ? parseInt(page) : query.page
                        // let url = `${window.location.origin}${window.location.pathname}?row_per_page=${query.pageSize}&page=${query.page}`
                        // query.search = search_text ? search_text : query.search
                        // if (query.search) {
                        //     url += `&search=${query.search}`
                        // }
                        // if (expenseDate) {
                        //     let createdFilter = []
                        //     let newCreated = expenseDate.split("to")
                        //     let start_date = newCreated[0]
                        //     let end_date = newCreated[1]
                        //     setRange([{ startDate: new Date(start_date), endDate: new Date(end_date), key: 'selection' }])
                        //     let column = {}
                        //     column.field = 'expense_at'
                        //     createdFilter.push({ 'column': column, 'value': [start_date, end_date] })
                        //     query.filters = createdFilter
                        // }
                        // setPage(null)
                        // setSearchText(null)
                        // setRowPerPage(query.pageSize)
                        // setExpenseDate(null)
                        // console.log(query.filters)
                        if (query.filters.length > 0) {
                            query.filters.map(data => {
                                // if (data.column.field == 'expense_at') {
                                //     let start_date = data.value[0]
                                //     let end_date = data.value[1]
                                //     url += `&expense_date=${start_date}to${end_date}`
                                // }
                                let column = {}
                                let value = []
                                column.field = data.column.field
                                if (data.column.field == 'wallet_id' || data.column.field == 'category_id' || data.column.field == 'refund_wallet_id') {
                                    if (data.value.length > 0) {
                                        data.value.map(v => {
                                            value.push(v)
                                        })
                                    }
                                } else {
                                    value = data.value
                                }
                                filters.push({ 'column': column, 'value': value })
                            })
                            filters = JSON.stringify(filters)
                        }
                        // let column = {}
                        // column.field = 'expense_at'
                        // filters.push({ 'column': column, 'value': ['01-09-2021', '30-09-2021'] })
                        // filters = JSON.stringify(filters)
                        // console.log(filters)
                        // window.history.pushState("html", 'title', `${url}`)
                        let start_date = format(range[0].startDate, 'dd-MM-yyyy')
                        let end_date = format(range[0].endDate, 'dd-MM-yyyy')
                        let expense_date = [start_date, end_date]
                        const result = await user_api.get(`expense?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}&filters=${filters}&expense_date=${JSON.stringify(expense_date)}`)
                        const { data } = result
                        resolve({
                            data: data.data,
                            page: query.page,
                            totalCount: data.meta.total,
                        })
                    })
                }
                options={{
                    pageSize: 20,
                    pageSizeOptions: [20, 50, 100, 200, 500],
                    sorting: false,
                    search: true,
                    filtering: true,
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: { width: 190 }
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    },
                    {
                        icon: 'add_box',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => setOpenAdd(true)
                    },
                    rowData => ({
                        icon: () => <Icon>restore</Icon>,
                        tooltip: 'Refund',
                        onClick: (event, rowData) => openRefund(rowData)
                    }),
                    rowData => ({
                        icon: () => <Icon>edit</Icon>,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => openEdit(rowData)
                    }),
                ]}
            />

            <Dialog open={openAdd} fullWidth={true} maxWidth="md">
                <form onSubmit={onAddOrUpdate} autoComplete="off">
                    <CustomDialogTitle onClose={onClose}>New Expense</CustomDialogTitle>
                    <DialogContent dividers>
                        <KeyboardDatePicker
                            variant="dialog"
                            placeholder="MM/DD/YYYY"
                            inputVariant="outlined"
                            value={expense?.expense_at ? new Date(expense?.expense_at) : null}
                            onChange={(date) => setExpense({ ...expense, expense_at: date })}
                            label="Expense Date"
                            disableFuture
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Autocomplete
                            id="Category"
                            onChange={(e, value) => setCategory(value)}
                            value={category}
                            fullWidth
                            options={categories}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Category" variant="outlined" required margin="normal" />}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <Autocomplete
                            id="From Wallet"
                            onChange={(e, value) => setWallet(value)}
                            value={wallet}
                            fullWidth
                            required
                            options={wallets}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="From Wallet" variant="outlined" required margin="normal" />}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <TextField id="amount" label="Amount" variant="outlined" fullWidth margin="normal"
                            required value={expense?.amount} type="number"
                            onChange={(e) => setExpense({ ...expense, amount: e.target.value })} />
                        <TextField id="description" label="Description" variant="outlined" fullWidth margin="normal"
                            value={expense?.description} multiline rows={4} required
                            onChange={(e) => setExpense({ ...expense, description: e.target.value })} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
                        {loading ?
                            <Button color="primary" variant="contained"><CircularProgress color="inherit" size={25} /></Button>
                            :
                            <Button type="submit" color="primary" variant="outlined">Save changes</Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={open} fullWidth={true} maxWidth="md">
                <form onSubmit={onRefund} autoComplete="off">
                    <CustomDialogTitle onClose={onClose}>Refund</CustomDialogTitle>
                    <DialogContent dividers>
                        <KeyboardDatePicker
                            variant="dialog"
                            placeholder="MM/DD/YYYY"
                            inputVariant="outlined"
                            value={refund?.refund_at ? new Date(refund?.refund_at) : null}
                            onChange={(date) => setRefund({ ...refund, refund_at: date })}
                            label="Refund Date"
                            disableFuture
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Autocomplete
                            id="Refund to"
                            // onChange={(e, value) => setRefund({ ...refund, refund_wallet_id: value?.id })}
                            onChange={(e, value) => setWallet(value)}
                            value={wallet}
                            fullWidth
                            options={wallets}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Refund To" variant="outlined" margin="normal" required />}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <TextField id="refund_amount" label="Refund Amount" variant="outlined" fullWidth
                            margin="normal" required value={refund?.refund_amount} type="number"
                            onChange={(e) => setRefund({ ...refund, refund_amount: e.target.value })} />
                        <TextField id="refund_note" label="Refund Note" variant="outlined" fullWidth margin="normal"
                            value={refund?.refund_note} onChange={(e) => setRefund({ ...refund, refund_note: e.target.value })} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
                        {loading ?
                            <Button color="primary" variant="contained"><CircularProgress color="inherit" size={25} /></Button>
                            :
                            <Button type="submit" color="primary" variant="outlined">Save changes</Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
export default Page