import React, { useState } from 'react'
import './App.css'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom"
import { AdminContainer, UserContainer } from './components'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, createTheme } from '@material-ui/core'
import context from 'services/context'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import WalletType from './pages/WalletType'
import Wallet from './pages/Wallet'
import Category from './pages/Category'
import Transaction from './pages/Transaction'
import Income from './pages/Income'
import Expense from './pages/Expense'
import NotFound from './pages/NotFound'
import Note from './pages/Note'
import Report from './pages/Report'
import Profile from './pages/Profile'

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans, Arial',
  },
  palette: {
    primary: {
      main: '#ffb6c1'
    }
  }
});
theme.typography.h1 = {
  fontSize: '2rem',
  letterSpacing: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem',
  }
}
theme.typography.h2 = {
  fontSize: '2rem',
  letterSpacing: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem',
  }
}
theme.typography.h3 = {
  fontSize: '1.8rem',
  letterSpacing: 0.9,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  }
}
theme.typography.h4 = {
  fontSize: '1.6rem',
  letterSpacing: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
  }
}
theme.typography.h5 = {
  fontSize: '1.4rem',
  letterSpacing: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
  }
}
theme.typography.h6 = {
  fontSize: '1rem',
  letterSpacing: 1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem',
  }
}

function App() {
  const [snack, setSnack] = useState(null)

  return (
    <context.Provider value={{ snack, setSnack }}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router>
            <Switch>
              <PrivateRoute path="/dashboard"><Dashboard /></PrivateRoute>
              <PrivateRoute path="/report"><Report /></PrivateRoute>
              <PrivateRoute path="/wallet-type"><WalletType /></PrivateRoute>
              <PrivateRoute path="/wallet"><Wallet /></PrivateRoute>
              <PrivateRoute path="/category"><Category /></PrivateRoute>
              <PrivateRoute path="/transaction"><Transaction /></PrivateRoute>
              <PrivateRoute path="/income"><Income /></PrivateRoute>
              <PrivateRoute path="/expense"><Expense /></PrivateRoute>
              <PrivateRoute path="/note"><Note /></PrivateRoute>
              <PrivateRoute path="/profile"><Profile /></PrivateRoute>
              <Route exact path="/"><Login /></Route>
              <Route component={NotFound} />
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </context.Provider>
  );
}

export default App


function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('user_token') ? (
          <UserContainer>
            {children}
          </UserContainer>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
