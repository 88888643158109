import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom';

const Page = ((props) => {

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h1" className="title-404">404</Typography>
                <Typography className="sub-title-404">Sorry, This page could not be found</Typography>
                <Typography className="body-404">It looks like this page you're looking for might have been removed, changed or is temporarily unavailable.</Typography>
            </Grid>
            <Grid item xs={5} className="mt20">
                <Link to="/" >
                    <Button variant="contained" color="primary" fullWidth size="large" className="btn-primary">Go to homepage</Button>
                </Link>
            </Grid>
        </Grid>
    )
})
export default Page