import React, { useContext, useEffect, useState } from 'react'
import { Container, Button, Popover, alpha, styled, CircularProgress, FormControl, Grid, Card, Paper, TextField, Typography, CssBaseline, Divider, InputAdornment, IconButton, Icon, Input, MenuItem, Link } from '@material-ui/core'
import { user_api, hp, context } from 'services'
import { useHistory } from 'react-router-dom'
import { format, addDays, subDays, startOfMonth } from 'date-fns'
import { DateRangePicker, DateRange } from 'react-date-range'
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2'
import { Fade } from 'react-reveal'

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
        theme.palette.primary.dark,
        0.24
    )} 100%)`
}));

const IncomeRootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.light
}));

const IncomeIconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.info.contrastText,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
        theme.palette.info.dark,
        0.24
    )} 100%)`
}));

const ExpenseRootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.dark
}));

const ExpenseIconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.error.contrastText,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
        theme.palette.error.contrastText,
        0.24
    )} 100%)`
}));

const ToPayRootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.light
}));
const ToPayIconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.error.contrastText,
    backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
        theme.palette.error.dark,
        0.24
    )} 100%)`
}));

const Page = () => {
    const history = useHistory()
    const { setSnack } = useContext(context)
    const [range, setRange] = useState([{ startDate: startOfMonth(new Date()), endDate: new Date(), key: 'selection' }])
    const [anchorEl, setAnchorEl] = useState(null)
    const [result, setResult] = useState(null)
    const [showBalance, setShowBalance] = useState(false)
    const [showIncome, setShowIncome] = useState(false)
    const [showExpense, setShowExpense] = useState(false)
    const [showGet, setShowGet] = useState(false)
    const [showPay, setShowPay] = useState(false)
    const [showIHave, setShowIHave] = useState(false)
    const [wallets, setWallets] = useState([])
    const [filterLoading, setFilterLoading] = useState(false)

    useEffect(() => {
        onStart()
    }, [])
    const showRange = Boolean(anchorEl)
    const onStart = async () => {
        let start_date = format(range[0].startDate, 'dd-MM-yyyy')
        let end_date = format(range[0].endDate, 'dd-MM-yyyy')
        const res = await user_api.get(`dashboard?start_date=${start_date}&end_date=${end_date}`)
        const { data } = res.data
        if (data.wallets) {
            setWallets(Object.keys(data.wallets))
        }
        setResult(data)
    }
    const onFilter = async () => {
        setFilterLoading(true)
        let start_date = format(range[0].startDate, 'dd-MM-yyyy')
        let end_date = format(range[0].endDate, 'dd-MM-yyyy')
        const res = await user_api.get(`dashboard?start_date=${start_date}&end_date=${end_date}`)
        const { data } = res.data
        setFilterLoading(false)
        if (data.wallets) {
            setWallets(Object.keys(data.wallets))
        }
        setResult(data)
    }

    const walletData = {
        labels: wallets,
        datasets: [
            {
                label: '# of Wallet',
                data: result?.wallets && Object.values(result.wallets),
                backgroundColor: [
                    'rgba(0, 84, 166, 1)',
                    'rgba(0, 146, 200, 1)',
                    'rgba(184, 11, 30, 1)',
                    'rgba(54, 83, 161, 1)',
                    'rgba(247, 212, 82, 1)',
                    'rgba(17, 172, 181, 1)',
                    'rgba(235, 42, 48, 1)',
                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 230, 10, 1)',
                    'rgba(97, 1, 5, 1)',
                    'rgba(253, 240, 10, 1)',
                    'rgba(38, 61, 141, 1)',
                    'rgba(112, 193, 85, 1)',
                    'rgba(254, 254, 254, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const onViewAll = (url, index) => {
        // history.push(url)
        window.location.href = `${window.location.origin}/${url}`
        localStorage.setItem('selectedIndex', index)
    }

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            {result ?
                <>
                    <Fade up cascade opposite>
                        <Paper className="p20">
                            <Grid container id="filter" justifyContent="center" alignItems="center">
                                <Grid item xs={9} sm={5} lg={3} xl={2}>
                                    <Typography>Please select a date range</Typography>
                                    <Button variant="outlined" color="primary" onClick={(e) => { setAnchorEl(e.currentTarget) }} >
                                        {format(range[0].startDate, 'dd-MM-yyyy')} &nbsp;&nbsp; to &nbsp;&nbsp; {format(range[0].endDate, 'dd-MM-yyyy')}
                                    </Button>
                                    <Popover
                                        open={showRange}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}>
                                        <DateRangePicker
                                            onChange={item => setRange([item.selection])}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={1}
                                            ranges={range}
                                            direction="vertical"
                                            maxDate={new Date()}
                                        />
                                    </Popover>
                                </Grid>
                                <Grid item xs={3} sm={2} className="mt20">
                                    {filterLoading ?
                                        <Button variant="contained" color="primary" fullWidth><CircularProgress size={25} color="inherit" /></Button>
                                        :
                                        <Button variant="contained" color="primary" fullWidth onClick={onFilter}>Filter</Button>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fade>
                    <Fade up cascade opposite>
                        <Grid container spacing={2} className="mt10">
                            <Grid item xs={12} sm={6} md={4}>
                                <RootStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => setShowBalance(!showBalance)} className="black">
                                            <Icon>{showBalance ? 'visibility' : 'visibility_off'}</Icon>
                                        </IconButton>
                                    </div>
                                    <IconWrapperStyle>
                                        <Icon>account_balance_wallet</Icon>
                                    </IconWrapperStyle>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            {showBalance ?
                                                <Typography variant="h3">{result && hp.FormatMoney(result?.total_amount)}</Typography> :
                                                <Typography variant="h3">*********</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>Balance (MMK)</Typography>
                                </RootStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <IncomeRootStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => setShowIncome(!showIncome)} className="white">
                                            <Icon>{showIncome ? 'visibility' : 'visibility_off'}</Icon>
                                        </IconButton>
                                    </div>
                                    <IncomeIconWrapperStyle>
                                        <Icon>local_atm</Icon>
                                    </IncomeIconWrapperStyle>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            {showIncome ?
                                                <Typography variant="h3">{result && hp.FormatMoney(result?.income_amount)}</Typography> :
                                                <Typography variant="h3">*********</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>Income (MMK)</Typography>
                                </IncomeRootStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ExpenseRootStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => setShowExpense(!showExpense)} className="white">
                                            <Icon>{showExpense ? 'visibility' : 'visibility_off'}</Icon>
                                        </IconButton>
                                    </div>
                                    <ExpenseIconWrapperStyle>
                                        <Icon>local_atm</Icon>
                                    </ExpenseIconWrapperStyle>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            {showExpense ?
                                                <Typography variant="h3">{result && hp.FormatMoney(result?.expense_amount)}</Typography> :
                                                <Typography variant="h3">*********</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>Expense (MMK)</Typography>
                                </ExpenseRootStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <IncomeRootStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => setShowGet(!showGet)} className="white">
                                            <Icon>{showGet ? 'visibility' : 'visibility_off'}</Icon>
                                        </IconButton>
                                    </div>
                                    <IncomeIconWrapperStyle>
                                        <Icon>local_atm</Icon>
                                    </IncomeIconWrapperStyle>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            {showGet ?
                                                <Typography variant="h3">{result && hp.FormatMoney(result?.to_gets)}</Typography> :
                                                <Typography variant="h3">*********</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>To Get (MMK)</Typography>
                                </IncomeRootStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <ToPayRootStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => setShowPay(!showPay)} className="white">
                                            <Icon>{showPay ? 'visibility' : 'visibility_off'}</Icon>
                                        </IconButton>
                                    </div>
                                    <ToPayIconWrapperStyle>
                                        <Icon>local_atm</Icon>
                                    </ToPayIconWrapperStyle>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            {showPay ?
                                                <Typography variant="h3">{result && hp.FormatMoney(result?.to_pays)}</Typography> :
                                                <Typography variant="h3">*********</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>To Pay (MMK)</Typography>
                                </ToPayRootStyle>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <RootStyle>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton onClick={() => setShowIHave(!showIHave)} className="black">
                                            <Icon>{showIHave ? 'visibility' : 'visibility_off'}</Icon>
                                        </IconButton>
                                    </div>
                                    <IconWrapperStyle>
                                        <Icon>account_balance_wallet</Icon>
                                    </IconWrapperStyle>
                                    <Grid container spacing={1} justifyContent="center">
                                        <Grid item>
                                            {showIHave ?
                                                <Typography variant="h3">{result && hp.FormatMoney(result?.total_amount - result?.to_pays)}</Typography> :
                                                <Typography variant="h3">*********</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>I Have (MMK)</Typography>
                                </RootStyle>
                            </Grid>
                            {result?.wallets &&
                                <Grid item xs={12} sm={6} md={4}>
                                    <Paper>
                                        <div className="p20">
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Typography>Wallets</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Link component="button" color="inherit" onClick={() => onViewAll('wallet', 2)}>View All</Link>
                                                </Grid>
                                            </Grid>
                                            <Pie data={walletData} />
                                        </div>
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                    </Fade>
                </> :
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={25} color="primary" />
                </div>
            }
        </div>
    )
}
export default Page