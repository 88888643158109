import React, { useContext, useEffect, useState } from 'react'
import { Container, Button, Popover, alpha, styled, CircularProgress, FormControl, Grid, Card, Paper, TextField, Typography, CssBaseline, Divider, InputAdornment, IconButton, Icon, Input, MenuItem, Link } from '@material-ui/core'
import { user_api, hp, context } from 'services'
import { useHistory } from 'react-router-dom'
import { format, addDays, subDays, startOfMonth, startOfYear } from 'date-fns'
import { DateRangePicker, DateRange } from 'react-date-range'
import { Bar, Line, Doughnut, Pie } from 'react-chartjs-2'
import { Fade } from 'react-reveal'

const Page = () => {
    const history = useHistory()
    const { setSnack } = useContext(context)
    const [range, setRange] = useState([{ startDate: startOfYear(new Date()), endDate: new Date(), key: 'selection' }])
    const [anchorEl, setAnchorEl] = useState(null)
    const [result, setResult] = useState(null)
    const [filterLoading, setFilterLoading] = useState(false)

    useEffect(() => {
        onStart()
    }, [])
    const showRange = Boolean(anchorEl)
    const onStart = async () => {
        let start_date = format(range[0].startDate, 'dd-MM-yyyy')
        let end_date = format(range[0].endDate, 'dd-MM-yyyy')
        const res = await user_api.get(`report?start_date=${start_date}&end_date=${end_date}`)
        const { data } = res
        setResult(data)
    }
    const onFilter = async () => {
        setFilterLoading(true)
        let start_date = format(range[0].startDate, 'dd-MM-yyyy')
        let end_date = format(range[0].endDate, 'dd-MM-yyyy')
        const res = await user_api.get(`report?start_date=${start_date}&end_date=${end_date}`)
        const { data } = res
        setFilterLoading(false)
        setResult(data)
    }

    const incomeExpenseTransactionData = {
        labels: result && Object.keys(result.income_by_month),
        datasets: [
            {
                label: '# of Income',
                data: result && Object.values(result.income_by_month),
                backgroundColor: 'rgb(54, 162, 235)',
            },
            {
                label: '# of Expenses',
                data: result && Object.values(result.expense_by_month),
                backgroundColor: 'rgb(255, 99, 132)',
            }
        ],
    };

    const onViewAll = (url, index) => {
        window.location.href = `${window.location.origin}/${url}`
        localStorage.setItem('selectedIndex', index)
    }

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            {result ?
                <>
                    <Fade up cascade opposite>
                        <Paper className="p20">
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={9} sm={5} lg={3} xl={2}>
                                    <Typography>Please select a date range</Typography>
                                    <Button variant="outlined" color="primary" onClick={(e) => { setAnchorEl(e.currentTarget) }} >
                                        {format(range[0].startDate, 'MM-yyyy')} &nbsp;&nbsp; to &nbsp;&nbsp; {format(range[0].endDate, 'MM-yyyy')}
                                    </Button>
                                    <Popover
                                        open={showRange}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}>
                                        <DateRange
                                            onChange={item => setRange([item.selection])}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={true}
                                            months={1}
                                            ranges={range}
                                            direction="vertical"
                                            maxDate={new Date()}
                                        />
                                    </Popover>
                                </Grid>
                                <Grid item xs={3} sm={2} className="mt20">
                                    {filterLoading ?
                                        <Button variant="contained" color="primary" fullWidth><CircularProgress size={25} color="inherit" /></Button>
                                        :
                                        <Button variant="contained" color="primary" fullWidth onClick={onFilter}>Filter</Button>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fade>
                    <Fade up cascade opposite>
                        <Grid container justifyContent="center" className="mt10">
                            <Grid item xs={12} sm={9} xl={8}>
                                <Paper>
                                    <div className="p20">
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Typography>Income & Expenses Statistics</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Link component="button" color="inherit" onClick={() => onViewAll('income', 5)}>View Incomes</Link>
                                                <Link component="button" color="inherit" onClick={() => onViewAll('expense', 6)} className="mr5">View Expenses</Link>
                                            </Grid>
                                        </Grid>
                                        <Bar data={incomeExpenseTransactionData} />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Fade>
                </>
                :
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={25} color="primary" />
                </div>
            }
        </div>
    )
}
export default Page