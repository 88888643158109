import React, { useState, useRef, useEffect, useContext } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { user_api, context } from 'services'
import { makeStyles, Typography, FormControlLabel, Checkbox, Button, Dialog, DialogContent, DialogActions, Icon, TextField, CircularProgress, MenuItem } from '@material-ui/core'
import { CustomDialogTitle } from 'components'
import { KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
    tbTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));

const Page = () => {
    const tableRef = useRef()
    const { setSnack } = useContext(context)
    const [openAdd, setOpenAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState({ note_at: null, type: null, note: null, amount: '', remark: '', is_done: false })
    const classes = useStyles()

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    const onAddOrUpdate = async (e) => {
        e.preventDefault()
        setLoading(true)
        let res
        if (note?.id) {
            res = await user_api.put(`note/${note.id}`, { ...note })
        } else {
            res = await user_api.post(`note`, { ...note })
        }
        let { data } = res
        setLoading(false)
        if (data.error) {
            showSnack(data.message, 'error')
            return
        } else {
            if (note?.id)
                showSnack("Successfully updated.", 'info')
            else
                showSnack("Successfully created.", 'info')
        }
        setOpenAdd(false)
        setNote({ note_at: null, type: null, note: null, amount: '', remark: '', is_done: false })
        tableRef.current && tableRef.current.onQueryChange()
    }

    const openEdit = (rowData) => {
        let _note = { ...note }
        _note.id = rowData?.id
        _note.amount = rowData?.amount
        _note.note_at = rowData?.note_at
        _note.remark = rowData?.remark
        _note.note = rowData?.note
        _note.type = rowData?.type
        setNote(_note)
        setOpenAdd(true)
    }

    const onClose = () => {
        setOpenAdd(false)
        setNote({ note_at: null, type: null, note: null, amount: '', remark: '', is_done: false })
    }

    return (
        <div>
            <MaterialTable tableRef={tableRef}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h5" className={`${classes.tbTitle}`}>Notes</Typography>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                columns={[
                    { title: 'Already done', field: 'is_done', type: 'boolean' },
                    {
                        title: 'Amount', field: 'amount', type: 'currency',
                        currencySetting: { currencyCode: 'MMK', minimumFractionDigits: 0 }
                    },
                    { title: 'Note', field: 'note' },
                    { title: 'Type', field: 'type', lookup: { 'To Pay': 'To Pay', 'To Get': 'To Get' } },
                    { title: 'Note at', field: 'note_at', type: 'date' },
                    { title: 'Remark', field: 'remark' }
                ]}
                data={query =>
                    new Promise(async (resolve) => {
                        let filters = []
                        if (query.filters.length > 0) {
                            query.filters.map(data => {
                                let column = {}
                                let value = []
                                column.field = data.column.field
                                value = data.value
                                filters.push({ 'column': column, 'value': value })
                            })
                            filters = JSON.stringify(filters)
                        }
                        const result = await user_api.get(`note?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}&filters=${filters}`)
                        const { data } = result
                        resolve({
                            data: data.data,
                            page: query.page,
                            totalCount: data.meta.total,
                        })
                    })
                }
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    sorting: false,
                    search: true,
                    filtering: true,
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: { width: 190 }
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    },
                    {
                        icon: 'add_box',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => setOpenAdd(true)
                    },
                    rowData => ({
                        icon: () => <Icon>edit</Icon>,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => openEdit(rowData)
                    })
                ]}
                editable={{
                    // onRowAdd: newData =>
                    //     new Promise(async (resolve, reject) => {
                    //         const res = await user_api.post('note', newData)
                    //         let { data } = res
                    //         if (data.error) {
                    //             showSnack(data.message, 'error')
                    //             reject()
                    //         } else {
                    //             showSnack("Successfully created.", 'info')
                    //         }
                    //         resolve()
                    //     }),
                    // onRowUpdate: newData =>
                    //     new Promise(async (resolve, reject) => {
                    //         const res = await user_api.put(`note/${newData.id}`, newData)
                    //         let { data } = res
                    //         if (data.error) {
                    //             showSnack(data.message, 'error')
                    //             reject()
                    //         } else {
                    //             showSnack("Successfully updated.", 'info')
                    //         }
                    //         resolve()
                    //     })
                }}
            />
            <Dialog open={openAdd} fullWidth={true} maxWidth="md">
                <form onSubmit={onAddOrUpdate} autoComplete="off">
                    <CustomDialogTitle onClose={onClose}>Note</CustomDialogTitle>
                    <DialogContent dividers>
                        <TextField id="note" label="Note" variant="outlined" fullWidth margin="normal"
                            required value={note?.note}
                            onChange={(e) => setNote({ ...note, note: e.target.value })} />
                        <KeyboardDatePicker
                            variant="dialog"
                            placeholder="MM/DD/YYYY"
                            inputVariant="outlined"
                            value={note?.note_at ? new Date(note?.note_at) : null}
                            onChange={(date) => setNote({ ...note, note_at: date })}
                            label="Note Date"
                            disableFuture
                            format="MM/dd/yyyy"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField id="type" label="Type" variant="outlined" fullWidth margin="normal"
                            required value={note?.type} select
                            onChange={(e) => setNote({ ...note, type: e.target.value })}>
                            <MenuItem value="To Pay">To Pay</MenuItem>
                            <MenuItem value="To Get">To Get</MenuItem>
                        </TextField>
                        <TextField id="amount" label="Amount" variant="outlined" fullWidth margin="normal"
                            required value={note?.amount} type="number"
                            onChange={(e) => setNote({ ...note, amount: e.target.value })} />
                        <TextField id="remark" label="Remark" variant="outlined" fullWidth margin="normal"
                            value={note?.remark} multiline rows={4}
                            onChange={(e) => setNote({ ...note, remark: e.target.value })} />
                        <FormControlLabel
                            control={<Checkbox checked={note?.is_done} onChange={(e) => setNote({ ...note, is_done: e.target.checked })} name="checkedA" />}
                            label="Already done"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
                        {loading ?
                            <Button color="primary" variant="contained"><CircularProgress color="inherit" size={25} /></Button>
                            :
                            <Button type="submit" color="primary" variant="outlined">Save changes</Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
export default Page