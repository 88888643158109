import React, { useState, useRef, useEffect, useContext } from 'react'
import { user_api, context, hp } from 'services'
import { Button, Icon, makeStyles, CircularProgress, Slide, Dialog, DialogActions, DialogContent, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, FormControl, TableRow, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { CustomDialogTitle } from 'components'

const useStyles = makeStyles((theme) => ({
    mt: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(3)
        },
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Page = () => {
    const classes = useStyles()
    const history = useHistory()
    const { setSnack } = useContext(context)
    const [open, setOpen] = useState(false)
    const [password, setPassword] = useState({ current_password: '', new_password: '', confirm_new_password: '' })
    const [profile, setProfile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [updateloading, setUpdateLoading] = useState(false)

    useEffect(() => {
        onStart()
    }, [])

    const onStart = async () => {
        const res = await user_api.get(`profile`)
        let { data } = res.data
        setProfile(data)
    }

    const onChangePassword = async (event) => {
        event.preventDefault();
        if (password.new_password != password.confirm_new_password) {
            showSnack("Password does not match.", 'error')
            return
        }
        setLoading(true)
        let res = await user_api.post(`change-password`, password)
        let data = res.data
        setLoading(false)
        if (data.error) {
            showSnack(data.message, 'error')
            return
        } else {
            showSnack("Password is changed and the user has been logged out from all sessions")
            setTimeout(async () => {
                hp.user_logout()
                history.push('/')
            }, 5000)
        }
    }

    const onUpdate = async (event) => {
        event.preventDefault();
        setUpdateLoading(true)
        let res = await user_api.post(`profile`, { name: profile?.name, email: profile?.email })
        const { data } = res
        setUpdateLoading(false)
        if (data.error) {
            showSnack(data.message)
            return
        } else {
            localStorage.setItem('name', profile?.name)
            setOpen(false)
        }
    }

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            {profile &&
                <>
                    <TableContainer component={Paper} style={{ marginBottom: 15 }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell>{profile?.name}</TableCell>
                                    <TableCell align="right">
                                        <Button variant="contained" color="primary" startIcon={<Icon>edit</Icon>} onClick={() => setOpen(true)}>Edit</Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Email</strong></TableCell>
                                    <TableCell colSpan={2}>{profile?.email}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Paper style={{ padding: 10 }}>
                        <Typography variant="h5" component="h2">Change Password</Typography>
                        <form autoComplete="off" onSubmit={onChangePassword}>
                            <TextField
                                value={password?.current_password}
                                margin="normal"
                                id="current_password"
                                label="Current Password"
                                type="password"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setPassword({ ...password, current_password: e.target.value })}
                                required
                            />
                            <TextField
                                value={password?.new_password}
                                margin="normal"
                                id="new_password"
                                label="New Password"
                                type="password"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setPassword({ ...password, new_password: e.target.value })}
                                helperText="Passwords must be at least 8 characters."
                                required
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <TextField
                                        value={password?.confirm_new_password}
                                        margin="normal"
                                        id="confirm_password"
                                        label="Enter Password One More Time"
                                        type="password"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setPassword({ ...password, confirm_new_password: e.target.value })}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} className={classes.mt}>
                                    <FormControl variant="outlined" fullWidth>
                                        {loading ?
                                            <Button variant="contained" color="primary" size="large"><CircularProgress color="inherit" size={25} /></Button>
                                            :
                                            <Button variant="contained" color="primary" size="large" type="submit">Change Password</Button>
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                    <Dialog open={open} TransitionComponent={Transition} keepMounted fullWidth={true} maxWidth="md" onClose={() => setOpen(false)}>
                        <CustomDialogTitle onClose={() => setOpen(false)} >Profile</CustomDialogTitle>
                        <form autoComplete="off" onSubmit={onUpdate}>
                            <DialogContent dividers>
                                <TextField
                                    value={profile?.name}
                                    margin="normal"
                                    id="name"
                                    label="Name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                                    required
                                />
                                <TextField
                                    value={profile?.email}
                                    margin="normal"
                                    id="email"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                                    required
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>Cancel</Button>
                                {updateloading ?
                                    <Button variant="contained" color="primary"><CircularProgress color="inherit" size={25} /></Button>
                                    :
                                    <Button variant="outlined" color="primary" type="submit">Save changes</Button>
                                }
                            </DialogActions>
                        </form>
                    </Dialog>
                </>
            }
        </div>
    )
}
export default Page