import React, { useState, useRef, useEffect, useContext } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { user_api, context } from 'services'
import { CustomDialogTitle } from 'components'
import { makeStyles, Typography, Button, Dialog, DialogContent, DialogActions, Icon, TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles((theme) => ({
    tbTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));

const Page = () => {
    const tableRef = useRef()
    const { setSnack } = useContext(context)
    const [lookupWallet, setLookupWallet] = useState(null)
    const [wallets, setWallets] = useState(null)
    const [from_wallet, setFromWallet] = useState(null)
    const [to_wallet, setToWallet] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [transaction, setTransaction] = useState({ from_wallet_id: null, to_wallet_id: null, amount: '', description: '' })
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        onStart()
    }, [])
    const onStart = async () => {
        const res = await user_api.get(`wallet?limit=100`)
        const { data } = res.data
        if (data.error) {
            showSnack(data.message)
            return
        } else {
            setWallets(data)
            let _data = {}
            for (var i = 0; i < data.length; i++) {
                _data[data[i].id] = data[i].name
            }
            setLookupWallet(_data)
        }
    }

    const openEdit = (rowData) => {
        let _transaction = { ...transaction }
        _transaction.id = rowData?.id
        _transaction.amount = rowData?.amount
        _transaction.description = rowData?.description
        wallets.forEach(element => {
            if (element.id == rowData?.from_wallet_id) {
                setFromWallet(element)
                return
            }
        });
        wallets.forEach(element => {
            if (element.id == rowData?.to_wallet_id) {
                setToWallet(element)
                return
            }
        });
        setTransaction(_transaction)
        setOpenAdd(true)
    }

    const onAddOrUpdate = async (e) => {
        e.preventDefault()
        setLoading(true)
        let res
        if (transaction?.id) {
            res = await user_api.put(`transaction/${transaction.id}`,
                { ...transaction, from_wallet_id: from_wallet?.id, to_wallet_id: to_wallet?.id })
        } else {
            res = await user_api.post(`transaction`, { ...transaction, from_wallet_id: from_wallet?.id, to_wallet_id: to_wallet?.id })
        }
        let { data } = res
        setLoading(false)
        if (data.error) {
            showSnack(data.message, 'error')
            return
        } else {
            if (transaction?.id)
                showSnack("Successfully updated.", 'info')
            else
                showSnack("Successfully created.", 'info')
        }
        setOpenAdd(false)
        setFromWallet(null)
        setToWallet(null)
        setTransaction({ from_wallet_id: null, to_wallet_id: null, amount: '', description: '' })
        tableRef.current && tableRef.current.onQueryChange()
    }

    const onClose = () => {
        setOpenAdd(false)
        setFromWallet(null)
        setToWallet(null)
        setTransaction({ from_wallet_id: null, to_wallet_id: null, amount: '', description: '' })
    }

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            <MaterialTable tableRef={tableRef}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h5" className={`${classes.tbTitle}`}>Transactions</Typography>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                columns={[
                    {
                        title: 'Amount', field: 'amount', type: 'currency', cellStyle: { width: '10%' },
                        currencySetting: { currencyCode: 'MMK', minimumFractionDigits: 0 }
                    },
                    { title: 'From Wallet', field: 'from_wallet_id', lookup: lookupWallet, cellStyle: { width: '30%' }, },
                    { title: 'To Wallet', field: 'to_wallet_id', lookup: lookupWallet, cellStyle: { width: '30%' }, },
                    { title: 'Description', field: 'description', cellStyle: { width: '20%' }, }
                ]}
                data={query =>
                    new Promise(async (resolve) => {
                        let filters = []
                        if (query.filters.length > 0) {
                            query.filters.map(data => {
                                let column = {}
                                let value = []
                                column.field = data.column.field
                                if (data.column.field == 'from_wallet_id' || data.column.field == 'to_wallet_id') {
                                    if (data.value.length > 0) {
                                        data.value.map(v => {
                                            value.push(v)
                                        })
                                    }
                                } else {
                                    value = data.value
                                }
                                filters.push({ 'column': column, 'value': value })
                            })
                            filters = JSON.stringify(filters)
                        }
                        const result = await user_api.get(`transaction?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}&filters=${filters}`)
                        const { data } = result
                        resolve({
                            data: data.data,
                            page: query.page,
                            totalCount: data.meta.total,
                        })
                    })
                }
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    sorting: false,
                    search: true,
                    filtering: true,
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: { width: 190 }
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    },
                    {
                        icon: 'add_box',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => setOpenAdd(true)
                    },
                    rowData => ({
                        icon: () => <Icon>edit</Icon>,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => openEdit(rowData)
                    }),
                ]}
            />
            <Dialog open={openAdd} fullWidth={true} maxWidth="md">
                <form onSubmit={onAddOrUpdate} autoComplete="off">
                    <CustomDialogTitle onClose={onClose}>New Transaction</CustomDialogTitle>
                    <DialogContent dividers>
                        <Autocomplete
                            id="From Wallet"
                            onChange={(e, value) => setFromWallet(value)}
                            value={from_wallet}
                            fullWidth
                            required
                            options={wallets}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="From Wallet" variant="outlined" required margin="normal" />}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <Autocomplete
                            id="To Wallet"
                            onChange={(e, value) => setToWallet(value)}
                            value={to_wallet}
                            fullWidth
                            required
                            options={wallets}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="From Wallet" variant="outlined" required margin="normal" />}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <TextField id="amount" label="Amount" variant="outlined" fullWidth margin="normal"
                            required value={transaction?.amount} type="number"
                            onChange={(e) => setTransaction({ ...transaction, amount: e.target.value })} />
                        <TextField id="description" label="Description" variant="outlined" fullWidth margin="normal"
                            value={transaction?.description} multiline rows={4} required
                            onChange={(e) => setTransaction({ ...transaction, description: e.target.value })} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
                        {loading ?
                            <Button color="primary" variant="contained"><CircularProgress color="inherit" size={25} /></Button>
                            :
                            <Button type="submit" color="primary" variant="outlined">Save changes</Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
export default Page