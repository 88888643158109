import React, { useState, useRef, useEffect, useContext } from 'react'
import { CustomDialogTitle } from 'components'
import MaterialTable, { MTableToolbar } from 'material-table'
import { user_api, context } from 'services'
import { makeStyles, Typography, Button, Dialog, DialogContent, DialogActions, Icon, TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles((theme) => ({
    tbTitle: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));

const Page = () => {
    const tableRef = useRef()
    const { setSnack } = useContext(context)
    const [lookupWalletType, setLookupWalletType] = useState(null)
    const [walletTypes, setWalletTypes] = useState(null)
    const [walletType, setWalletType] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [wallet, setWallet] = useState({ name: null, account_number: '', wallet_type_id: null, amount: '' })
    const classes = useStyles()

    useEffect(() => {
        onStart()
    }, [])
    const onStart = async () => {
        const res = await user_api.get(`wallet-type?limit=100`)
        const { data } = res.data
        if (data.error) {
            showSnack(data.message)
            return
        } else {
            setWalletTypes(data)
            let _data = {}
            for (var i = 0; i < data.length; i++) {
                _data[data[i].id] = data[i].name
            }
            setLookupWalletType(_data)
        }
    }

    const openEdit = (rowData) => {
        let _wallet = { ...wallet }
        _wallet.id = rowData?.id
        _wallet.amount = rowData?.amount
        _wallet.name = rowData?.name
        _wallet.account_number = rowData?.account_number
        walletTypes.forEach(element => {
            if (element.id == rowData?.wallet_type_id) {
                setWalletType(element)
                return
            }
        });
        setWallet(_wallet)
        setOpenAdd(true)
    }

    const onAddOrUpdate = async (e) => {
        e.preventDefault()
        setLoading(true)
        let res
        if (wallet?.id) {
            res = await user_api.put(`wallet/${wallet.id}`, { ...wallet, wallet_type_id: walletType?.id })
        } else {
            res = await user_api.post(`wallet`, { ...wallet, wallet_type_id: walletType?.id })
        }
        let { data } = res
        setLoading(false)
        if (data.error) {
            showSnack(data.message, 'error')
            return
        } else {
            if (wallet?.id)
                showSnack("Successfully updated.", 'info')
            else
                showSnack("Successfully created.", 'info')
        }
        setOpenAdd(false)
        setWalletType(null)
        setWallet({ name: null, account_number: '', wallet_type_id: null, amount: '' })
        tableRef.current && tableRef.current.onQueryChange()
    }

    const onClose = () => {
        setOpenAdd(false)
        setWalletType(null)
        setWallet({ name: null, account_number: '', wallet_type_id: null, amount: '' })
    }

    const showSnack = (message, type) => {
        setSnack({
            open: true, message: message, type: type, key: type == 'error' ? 'add-error' : 'success', onClose: () => {
                setSnack({ open: false })
            }
        })
    }

    return (
        <div>
            <MaterialTable tableRef={tableRef}
                components={{
                    Toolbar: props => (
                        <div>
                            <Typography variant="h5" className={`${classes.tbTitle}`}>Wallets</Typography>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                columns={[
                    { title: 'Name', field: 'name', cellStyle: { width: '40%' }, },
                    {
                        title: 'Amount', field: 'amount', type: 'currency', cellStyle: { width: '15%' },
                        currencySetting: { currencyCode: 'MMK', minimumFractionDigits: 0 }
                    },
                    // { title: 'Account Number', field: 'account_number', cellStyle: { width: '30%' }, },
                    { title: 'Wallet Type', field: 'wallet_type_id', lookup: lookupWalletType, cellStyle: { width: '45%' }, }
                ]}
                data={query =>
                    new Promise(async (resolve) => {
                        let filters = []
                        if (query.filters.length > 0) {
                            query.filters.map(data => {
                                let column = {}
                                let value = []
                                column.field = data.column.field
                                if (data.column.field == 'wallet_type_id') {
                                    if (data.value.length > 0) {
                                        data.value.map(v => {
                                            value.push(v)
                                        })
                                    }
                                } else {
                                    value = data.value
                                }
                                filters.push({ 'column': column, 'value': value })
                            })
                            filters = JSON.stringify(filters)
                        }
                        const result = await user_api.get(`wallet?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}&filters=${filters}`)
                        const { data } = result
                        resolve({
                            data: data.data,
                            page: query.page,
                            totalCount: data.meta.total,
                        })
                    })
                }
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    sorting: false,
                    search: true,
                    filtering: true,
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                    showTitle: false,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: { width: 190 }
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    },
                    {
                        icon: 'add_box',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: () => setOpenAdd(true)
                    },
                    rowData => ({
                        icon: () => <Icon>edit</Icon>,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => openEdit(rowData)
                    })
                ]}
                editable={{
                    // onRowAdd: newData =>
                    //     new Promise(async (resolve, reject) => {
                    //         const res = await user_api.post('wallet', newData)
                    //         let { data } = res
                    //         if (data.error) {
                    //             showSnack(data.message, 'error')
                    //             reject()
                    //         } else {
                    //             showSnack("Successfully created.", 'info')
                    //         }
                    //         resolve()
                    //     }),
                    // onRowUpdate: newData =>
                    //     new Promise(async (resolve, reject) => {
                    //         delete newData?.wallet_type
                    //         const res = await user_api.put(`wallet/${newData.id}`, newData)
                    //         let { data } = res
                    //         if (data.error) {
                    //             showSnack(data.message, 'error')
                    //             reject()
                    //         } else {
                    //             showSnack("Successfully updated.", 'info')
                    //         }
                    //         resolve()
                    //     }),
                }}
            />
            <Dialog open={openAdd} fullWidth={true} maxWidth="md">
                <form onSubmit={onAddOrUpdate} autoComplete="off">
                    <CustomDialogTitle onClose={onClose}>New Wallet</CustomDialogTitle>
                    <DialogContent dividers>
                        <Autocomplete
                            id="Wallet Type"
                            onChange={(e, value) => setWalletType(value)}
                            value={walletType}
                            fullWidth
                            required
                            options={walletTypes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Wallet Type" variant="outlined" required margin="normal" />}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                        />
                        <TextField id="name" label="Name" variant="outlined" fullWidth margin="normal"
                            value={wallet?.name} required
                            onChange={(e) => setWallet({ ...wallet, name: e.target.value })} />
                        <TextField id="account_number" label="Account Number" variant="outlined" fullWidth margin="normal"
                            value={wallet?.account_number}
                            onChange={(e) => setWallet({ ...wallet, account_number: e.target.value })} />
                        <TextField id="amount" label="Amount" variant="outlined" fullWidth margin="normal"
                            required value={wallet?.amount} type="number"
                            onChange={(e) => setWallet({ ...wallet, amount: e.target.value })} />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={onClose}>Cancel</Button>
                        {loading ?
                            <Button color="primary" variant="contained"><CircularProgress color="inherit" size={25} /></Button>
                            :
                            <Button type="submit" color="primary" variant="outlined">Save changes</Button>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
export default Page